<template>
    <section>
        <div class="d-flex flex-row justify-content-between align-items-center container">
            <div>
                <h3 class="card-title">
                    Total de Notificações: 
                    <b>{{notifications.length}}</b>
                </h3>
            </div>
            <div class="d-flex flex-row card-tools container-header">
                <button
                    class="btn btn-outline-default btn-sm mr-1"
                    title="Criar Notificação"
                    @click="createNotification()"
                >
                    <i class="fas fa-plus"></i>
                </button>
                <button
                    class="btn btn-outline-default btn-sm mr-1"
                    title="Atualizar"
                    @click="getNotifications()"
                >
                    <i class="fas fa-sync"></i>
                </button>
                <div class="d-flex flex-row align-items-center">
                    <input
                        v-model="searchNotification"
                        title="Pesquisar Empresa"
                        type="text"
                        name="table_search"
                        class="form-control float-right input-search"
                        :placeholder="`Pesquisar`"
                        @keyup.enter="getNotifications()"
                    />
                    <i
                        class="fas fa-search lupa-style"
                        title="Perquisar empresa"
                    ></i>
                </div>
            </div>
        </div>
        <hr class="mt-3"/>
        <div v-if="notifications.length > 0">
            <table class="table table-hover table-stripped table-head-fixed">
                <thead>
                    <tr>
                        <th class="table-title">Titulo</th>
                        <th>Tipo</th>
                        <th>Criado em</th>
                        <th>Valido até</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(notification, i) in notifications" :key="i">
                        <td>{{notification.title}}</td>
                        <td>{{notification.type}}</td>
                        <td>{{new Date(notification.created_at).toLocaleString()}}</td>
                        <td>{{new Date(notification.expires_in).toLocaleString()}}</td>
                        <td>
                            <button
                                class="btn btn-outline-default btn-sm"
                                title="Editar Notificação"
                                @click="editNotification(notification)"
                            >
                                <i class="fa fa-edit"></i>
                            </button>
                            <button
                                class="btn btn-outline-default text-danger btn-sm"
                                title="Deletar Notificação"
                                @click="deleteNotification(notification, i)"
                            >
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="d-flex flex-row justify-content-center align-items-center">
            <div v-if="!loading">
                <label class="mt-1">Nenhuma Notificação Encontrada.</label>
            </div>
            <div v-else>
                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                <label class="mt-1">Buscando Notificações...</label>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    mounted () {
        this.getNotifications();
    },
    data () {
        return {
            selectedNotification: [],
            notifications: [],
            searchNotification: '',
            loading: false,
        }
    },
    methods: {
        /**
         * Metodo que busca as notificações
         */
        getNotifications: async function () {
            let url = 'notifications';
            this.loading = true;
            this.notifications = [];
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                    `Bearer ${this.$store.getters.tokenJWT}`;

            if (this.searchNotification) {
                url += `?search=${this.searchNotification}`
            }

            return await this.$tallos_admin_api.get(url)
                .then(response => {
                    this.notifications = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$toasted.global.defaultError({message: "erro ao fazer upload", err: error})
                })
        },

        /**
         * Metodo que cria uma nova notificação
         */
        createNotification: function () {
            this.$emit('create_notification');
        },

        /**
         * Metodo que emite a edição da notificação
         * @param {Object} notification data da notificação
         */
        editNotification: function (notification) {
            this.$emit('select_notification', notification);
        },

        /**
         * Metodo que realiza a exclusão da notificação
         * @param {Object} notification data da notificação
         */
        deleteNotification: async function (notification, index) {
            this.DeleteLoading = true;
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                    `Bearer ${this.$store.getters.tokenJWT}`;

            return await this.$tallos_admin_api.delete(`notifications/${notification._id}?employee=${this.$store.getters.admin.name}`)
                .then(response => {
                    this.getNotifications();
                })
                .catch(error => {
                    this.DeleteLoading = false;
                    this.$toasted.global.defaultError({message: "erro ao fazer upload", err: error})
                })
        }
    },
    props: [] 
}
</script>

<style scoped>
.container {
    padding-left: 0px;
}

.container-header {
}

.lupa-style {
    position: absolute;
    margin-left: 195px;
}

.input-search {
    border-radius: 16px;
}

.table-title {
    width: 400px;
}
</style>